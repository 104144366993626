// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Tooltip = require("../../common/Tooltip.bs.js");
var UTCDate = require("../../common/types/UTCDate.bs.js");
var DateFns = require("date-fns");
var Caml_option = require("rescript/lib/js/caml_option.js");
var TextLabel__Jsx3 = require("../../../uikit/reason/atoms/Labels/TextLabel__Jsx3.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Colors = require("uikit/styles/colors");

function stringOfExpiring(days_left) {
  if (days_left <= 0) {
    return "Expiring today";
  } else {
    return "Expiring in " + (String(days_left) + (
              days_left === 1 ? " day" : " days"
            ));
  }
}

function fromMlsPlans(timezone, activeOnMls, mlsPlans) {
  if (activeOnMls) {
    switch (mlsPlans.TAG) {
      case "Active" :
      case "ComingSoon" :
          break;
      case "OffMls" :
          return "offMls";
      
    }
    return {
            NAME: "wentActive",
            VAL: UTCDate.toZonedDate(mlsPlans._0.onMlsAt, timezone)
          };
  }
  switch (mlsPlans.TAG) {
    case "Active" :
        return {
                NAME: "goingActive",
                VAL: UTCDate.toZonedDate(mlsPlans._0.onMlsAt, timezone)
              };
    case "ComingSoon" :
        return {
                NAME: "comingSoon",
                VAL: UTCDate.toZonedDate(mlsPlans._0.onMlsAt, timezone)
              };
    case "OffMls" :
        return "offMls";
    
  }
}

function fromRentalMlsPlans(timezone, activeOnMls, mlsPlans) {
  if (activeOnMls) {
    if (typeof mlsPlans !== "object") {
      return "offMls";
    } else {
      return {
              NAME: "wentActive",
              VAL: UTCDate.toZonedDate(mlsPlans._0, timezone)
            };
    }
  } else if (typeof mlsPlans !== "object") {
    return "offMls";
  } else if (mlsPlans.TAG === "Active") {
    return {
            NAME: "goingActive",
            VAL: UTCDate.toZonedDate(mlsPlans._0, timezone)
          };
  } else {
    return {
            NAME: "comingSoon",
            VAL: UTCDate.toZonedDate(mlsPlans._0, timezone)
          };
  }
}

var Variant = {
  fromMlsPlans: fromMlsPlans,
  fromRentalMlsPlans: fromRentalMlsPlans
};

function tooltip(x) {
  if (typeof x !== "object") {
    return ;
  }
  var variant = x.NAME;
  if (variant === "goingActive") {
    return ;
  } else if (variant === "comingSoon") {
    return "Planned to go Active in the MLS on " + DateFns.format(x.VAL, "MMM d");
  } else {
    return "Went Active in the MLS on " + DateFns.format(x.VAL, "MMM d");
  }
}

function Labels$MLSLabel(props) {
  var variant = props.variant;
  var tmp;
  if (typeof variant === "object") {
    var variant$1 = variant.NAME;
    tmp = variant$1 === "goingActive" ? "On MLS " + DateFns.format(variant.VAL, "MMM d") : (
        variant$1 === "comingSoon" ? "MLS coming soon" : "On MLS"
      );
  } else {
    tmp = "OFF-MLS";
  }
  var textLabel = JsxRuntime.jsx(TextLabel__Jsx3.make, {
        borderColor: Colors.alto,
        children: Caml_option.some(tmp)
      });
  var tooltip$1 = tooltip(variant);
  if (tooltip$1 !== undefined) {
    return JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx(Tooltip.make, {
                      label: tooltip$1,
                      children: JsxRuntime.jsx("span", {
                            children: textLabel
                          })
                    })
              });
  } else {
    return textLabel;
  }
}

var MLSLabel = {
  Variant: Variant,
  tooltip: tooltip,
  make: Labels$MLSLabel
};

function Labels$QuietSellerLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              borderColor: Colors.tussock,
              children: "Quiet Seller"
            });
}

var QuietSellerLabel = {
  make: Labels$QuietSellerLabel
};

function Labels$AnonymousAgentLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              borderColor: Colors.tussock,
              children: "Hidden Agent"
            });
}

var AnonymousAgentLabel = {
  make: Labels$AnonymousAgentLabel
};

function Labels$OneWayMatchingLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              borderColor: Colors.tussock,
              children: "One Way Matching"
            });
}

var OneWayMatchingLabel = {
  make: Labels$OneWayMatchingLabel
};

function Labels$AnonymousMatchingLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              borderColor: Colors.tussock,
              children: "Anonymous Matching"
            });
}

var AnonymousMatchingLabel = {
  make: Labels$AnonymousMatchingLabel
};

function Labels$QuietBuyerLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              borderColor: Colors.tussock,
              children: "Quiet Buyer"
            });
}

var QuietBuyerLabel = {
  make: Labels$QuietBuyerLabel
};

function Labels$ActiveStatusLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              color: Colors.chelseaCucumber,
              children: "ACTIVE"
            });
}

var ActiveStatusLabel = {
  make: Labels$ActiveStatusLabel
};

function Labels$InactiveStatusLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              color: Colors.mineShaft,
              children: Caml_option.some(props.text)
            });
}

var InactiveStatusLabel = {
  make: Labels$InactiveStatusLabel
};

function Labels$ExpiredStatusLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              color: Colors.crimson,
              children: "EXPIRED"
            });
}

var ExpiredStatusLabel = {
  make: Labels$ExpiredStatusLabel
};

function Labels$ExpiringStatusLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              color: Colors.chelseaCucumber,
              explanationMark: props.expiringIn,
              children: "ACTIVE"
            });
}

var ExpiringStatusLabel = {
  make: Labels$ExpiringStatusLabel
};

function Labels$WithdrawnLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "WITHDRAWN"
            });
}

var WithdrawnLabel = {
  make: Labels$WithdrawnLabel
};

function Labels$SoldLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "SOLD"
            });
}

var SoldLabel = {
  make: Labels$SoldLabel
};

function Labels$RentedLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "RENTED"
            });
}

var RentedLabel = {
  make: Labels$RentedLabel
};

function Labels$RemovedLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "REMOVED"
            });
}

var RemovedLabel = {
  make: Labels$RemovedLabel
};

function Labels$UnderContractLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "UNDER CONTRACT"
            });
}

var UnderContractLabel = {
  make: Labels$UnderContractLabel
};

function Labels$InContractLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "IN CONTRACT"
            });
}

var InContractLabel = {
  make: Labels$InContractLabel
};

function Labels$PurchasedLabel(props) {
  return JsxRuntime.jsx(Labels$InactiveStatusLabel, {
              text: "PURCHASED"
            });
}

var PurchasedLabel = {
  make: Labels$PurchasedLabel
};

function Labels$RentalPropertyStatusLabel(props) {
  var status = props.status;
  if (typeof status === "object") {
    return JsxRuntime.jsx(Labels$ExpiringStatusLabel, {
                expiringIn: stringOfExpiring(status._0)
              });
  }
  switch (status) {
    case "Active" :
        return JsxRuntime.jsx(Labels$ActiveStatusLabel, {});
    case "Withdrawn" :
        return JsxRuntime.jsx(Labels$WithdrawnLabel, {});
    case "Rented" :
        return JsxRuntime.jsx(Labels$RentedLabel, {});
    case "Expired" :
        return JsxRuntime.jsx(Labels$ExpiredStatusLabel, {});
    case "Removed" :
        return JsxRuntime.jsx(Labels$RemovedLabel, {});
    
  }
}

var RentalPropertyStatusLabel = {
  make: Labels$RentalPropertyStatusLabel
};

function Labels$PropertyStatusLabel(props) {
  var status = props.status;
  if (typeof status === "object") {
    if (status.TAG === "Expiring") {
      return JsxRuntime.jsx(Labels$ExpiringStatusLabel, {
                  expiringIn: stringOfExpiring(status._0)
                });
    } else {
      return JsxRuntime.jsx(Labels$SoldLabel, {});
    }
  }
  switch (status) {
    case "Active" :
        return JsxRuntime.jsx(Labels$ActiveStatusLabel, {});
    case "Expired" :
        return JsxRuntime.jsx(Labels$ExpiredStatusLabel, {});
    case "Withdrawn" :
        return JsxRuntime.jsx(Labels$WithdrawnLabel, {});
    case "Removed" :
        return JsxRuntime.jsx(Labels$RemovedLabel, {});
    case "UnderContract" :
        return JsxRuntime.jsx(Labels$UnderContractLabel, {});
    
  }
}

var PropertyStatusLabel = {
  make: Labels$PropertyStatusLabel
};

function Labels$BuyerNeedStatusLabel(props) {
  var status = props.status;
  if (typeof status === "object") {
    return JsxRuntime.jsx(Labels$ExpiringStatusLabel, {
                expiringIn: stringOfExpiring(status._0)
              });
  }
  switch (status) {
    case "Active" :
        return JsxRuntime.jsx(Labels$ActiveStatusLabel, {});
    case "Expired" :
        return JsxRuntime.jsx(Labels$ExpiredStatusLabel, {});
    case "Purchased" :
        return JsxRuntime.jsx(Labels$PurchasedLabel, {});
    case "Withdrawn" :
        return JsxRuntime.jsx(Labels$WithdrawnLabel, {});
    case "Removed" :
        return JsxRuntime.jsx(Labels$RemovedLabel, {});
    case "InContract" :
        return JsxRuntime.jsx(Labels$InContractLabel, {});
    
  }
}

var BuyerNeedStatusLabel = {
  make: Labels$BuyerNeedStatusLabel
};

function Labels$RentalNeedStatusLabel(props) {
  var status = props.status;
  if (typeof status === "object") {
    return JsxRuntime.jsx(Labels$ExpiringStatusLabel, {
                expiringIn: stringOfExpiring(status._0)
              });
  }
  switch (status) {
    case "Active" :
        return JsxRuntime.jsx(Labels$ActiveStatusLabel, {});
    case "Withdrawn" :
        return JsxRuntime.jsx(Labels$WithdrawnLabel, {});
    case "Rented" :
        return JsxRuntime.jsx(Labels$RentedLabel, {});
    case "Expired" :
        return JsxRuntime.jsx(Labels$ExpiredStatusLabel, {});
    case "Removed" :
        return JsxRuntime.jsx(Labels$RemovedLabel, {});
    
  }
}

var RentalNeedStatusLabel = {
  make: Labels$RentalNeedStatusLabel
};

function Labels$RentalPropertyLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              borderColor: Colors.galliano,
              bg: Colors.citrineWhite,
              children: "Rental"
            });
}

var RentalPropertyLabel = {
  make: Labels$RentalPropertyLabel
};

function Labels$ForSaleLabel(props) {
  return JsxRuntime.jsx(TextLabel__Jsx3.make, {
              borderColor: Colors.astral,
              bg: Colors.aquaHaze,
              children: "For Sale"
            });
}

var ForSaleLabel = {
  make: Labels$ForSaleLabel
};

function Labels$ListingTypeLabel(props) {
  if (props.listingType === "Rental") {
    return JsxRuntime.jsx(Labels$RentalPropertyLabel, {});
  } else {
    return JsxRuntime.jsx(Labels$ForSaleLabel, {});
  }
}

var ListingTypeLabel = {
  make: Labels$ListingTypeLabel
};

exports.stringOfExpiring = stringOfExpiring;
exports.MLSLabel = MLSLabel;
exports.QuietSellerLabel = QuietSellerLabel;
exports.AnonymousAgentLabel = AnonymousAgentLabel;
exports.OneWayMatchingLabel = OneWayMatchingLabel;
exports.AnonymousMatchingLabel = AnonymousMatchingLabel;
exports.QuietBuyerLabel = QuietBuyerLabel;
exports.ActiveStatusLabel = ActiveStatusLabel;
exports.InactiveStatusLabel = InactiveStatusLabel;
exports.ExpiredStatusLabel = ExpiredStatusLabel;
exports.ExpiringStatusLabel = ExpiringStatusLabel;
exports.WithdrawnLabel = WithdrawnLabel;
exports.SoldLabel = SoldLabel;
exports.RentedLabel = RentedLabel;
exports.RemovedLabel = RemovedLabel;
exports.UnderContractLabel = UnderContractLabel;
exports.InContractLabel = InContractLabel;
exports.PurchasedLabel = PurchasedLabel;
exports.RentalPropertyStatusLabel = RentalPropertyStatusLabel;
exports.PropertyStatusLabel = PropertyStatusLabel;
exports.BuyerNeedStatusLabel = BuyerNeedStatusLabel;
exports.RentalNeedStatusLabel = RentalNeedStatusLabel;
exports.RentalPropertyLabel = RentalPropertyLabel;
exports.ForSaleLabel = ForSaleLabel;
exports.ListingTypeLabel = ListingTypeLabel;
/* Tooltip Not a pure module */
