// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css_Color = require("../../../../reason/common/Theme/Css_Color.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Api = require("@react-google-maps/api");

var LoadScript = {};

var Options = {};

var JS = {};

function GoogleMaps$GoogleMap(props) {
  var __center = props.center;
  var __zoom = props.zoom;
  var zoom = __zoom !== undefined ? __zoom : 14;
  var center = __center !== undefined ? Caml_option.valFromOption(__center) : ({
        lat: 0,
        lng: 0
      });
  return JsxRuntime.jsx(Api.GoogleMap, {
              id: props.id,
              mapContainerStyle: props.mapContainerStyle,
              mapContainerClassName: props.mapContainerClassName,
              center: center,
              zoom: zoom,
              options: props.options,
              children: props.children
            });
}

var GoogleMap = {
  Options: Options,
  JS: JS,
  make: GoogleMaps$GoogleMap
};

var Marker = {};

function make(strokeColor, fillColor) {
  var arg = Core__Option.map(fillColor, Css_Color.toJs);
  return function (param, param$1) {
    var partial_arg = Core__Option.map(strokeColor, Css_Color.toJs);
    return function (param$2, param$3) {
      var tmp = {};
      if (partial_arg !== undefined) {
        tmp.strokeColor = Caml_option.valFromOption(partial_arg);
      }
      if (param !== undefined) {
        tmp.strokeOpacity = Caml_option.valFromOption(param);
      }
      if (param$1 !== undefined) {
        tmp.strokeWeight = Caml_option.valFromOption(param$1);
      }
      if (arg !== undefined) {
        tmp.fillColor = Caml_option.valFromOption(arg);
      }
      if (param$2 !== undefined) {
        tmp.fillOpacity = Caml_option.valFromOption(param$2);
      }
      return tmp;
    };
  };
}

var Options$1 = {
  make: make
};

var Circle = {
  Options: Options$1
};

exports.LoadScript = LoadScript;
exports.GoogleMap = GoogleMap;
exports.Marker = Marker;
exports.Circle = Circle;
/* react/jsx-runtime Not a pure module */
