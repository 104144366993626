// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Env = require("../../../reason/common/Env.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Loader = require("../../../uikit/reason/atoms/Loader/Loader.bs.js");
var GoogleMaps = require("../../../uikit/reason/atoms/GoogleMaps/GoogleMaps.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Theme__Color = require("../../../reason/common/Theme/Theme__Color.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FragmentErrorBoundary = require("../ErrorHandling/FragmentErrorBoundary.bs.js");
var Api = require("@react-google-maps/api");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var UnselectedUnionMemberError = require("../ErrorHandling/UnselectedUnionMemberError.bs.js");
var PropertyLocationMap_property_graphql = require("../../../__generated__/PropertyLocationMap_property_graphql.bs.js");
var PropertyLocationMapInner_location_graphql = require("../../../__generated__/PropertyLocationMapInner_location_graphql.bs.js");

var googleKey = Core__Option.getExn(Env.google_key);

function PropertyLocationMap$Map(props) {
  var __size = props.size;
  var size = __size !== undefined ? __size : "large";
  var match = Api.useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: googleKey
      });
  var tmp;
  if (match.isLoaded) {
    var tmp$1 = {};
    var tmp$2 = size === "medium" ? 24 : undefined;
    if (tmp$2 !== undefined) {
      tmp$1.controlSize = Caml_option.valFromOption(tmp$2);
    }
    tmp = JsxRuntime.jsx(GoogleMaps.GoogleMap.make, {
          mapContainerClassName: "flex-grow relative",
          center: {
            lat: props.lat,
            lng: props.lon
          },
          options: Caml_option.some(tmp$1),
          children: Caml_option.some(props.children)
        });
  } else {
    tmp = JsxRuntime.jsx(Loader.make, {});
  }
  var heightPx = size === "medium" ? "h-[380px]" : "h-[480px]";
  return JsxRuntime.jsx("div", {
              children: tmp,
              className: heightPx + " flex items-stretch justify-center"
            });
}

var $$Map = {
  googleKey: googleKey,
  make: PropertyLocationMap$Map
};

var convertFragment = PropertyLocationMapInner_location_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(PropertyLocationMapInner_location_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PropertyLocationMapInner_location_graphql.node, convertFragment);
}

var PropertyLocationFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function PropertyLocationMap$FromPropertyLocation(props) {
  var size = props.size;
  var propertyLocation = RescriptRelay_Fragment.useFragment(PropertyLocationMapInner_location_graphql.node, convertFragment, props.propertyLocation);
  var variant = propertyLocation.NAME;
  if (variant === "PropertyLocationWithVisibleAddress") {
    var match = propertyLocation.VAL.coordinates;
    var lon = match.lon;
    var lat = match.lat;
    return JsxRuntime.jsx(PropertyLocationMap$Map, {
                lat: lat,
                lon: lon,
                size: size,
                children: JsxRuntime.jsx(Api.Marker, {
                      position: {
                        lat: lat,
                        lng: lon
                      }
                    })
              });
  }
  if (variant === "UnselectedUnionMember") {
    return UnselectedUnionMemberError.raise("Unselected propertyLocation: " + propertyLocation.VAL);
  }
  var match$1 = propertyLocation.VAL.approximateCoordinates;
  var lon$1 = match$1.lon;
  var lat$1 = match$1.lat;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(PropertyLocationMap$Map, {
                      lat: lat$1,
                      lon: lon$1,
                      size: size,
                      children: JsxRuntime.jsx(Api.Circle, {
                            center: {
                              lat: lat$1,
                              lng: lon$1
                            },
                            radius: 1000,
                            options: Caml_option.some(Curry._6(GoogleMaps.Circle.Options.make, Theme__Color.youtube, Theme__Color.youtube, 0.85, 2, 0.15, undefined))
                          })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx("span", {
                            children: "To protect seller privacy, location is approximate",
                            className: "text-sm"
                          }),
                      className: "pt-1 pl-1"
                    })
              ]
            });
}

var FromPropertyLocation = {
  PropertyLocationFragment: PropertyLocationFragment,
  make: PropertyLocationMap$FromPropertyLocation
};

var convertFragment$1 = PropertyLocationMap_property_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(PropertyLocationMap_property_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PropertyLocationMap_property_graphql.node, convertFragment$1);
}

var PropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1
};

function PropertyLocationMap$WithoutErrorBoundary(props) {
  var property = RescriptRelay_Fragment.useFragment(PropertyLocationMap_property_graphql.node, convertFragment$1, props.property);
  return JsxRuntime.jsx(PropertyLocationMap$FromPropertyLocation, {
              propertyLocation: property.propertyLocation.fragmentRefs,
              size: props.size
            });
}

var WithoutErrorBoundary = {
  make: PropertyLocationMap$WithoutErrorBoundary
};

function PropertyLocationMap(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "map",
              children: JsxRuntime.jsx(PropertyLocationMap$WithoutErrorBoundary, {
                    property: props.property,
                    size: props.size
                  })
            });
}

var make = PropertyLocationMap;

exports.$$Map = $$Map;
exports.FromPropertyLocation = FromPropertyLocation;
exports.PropertyFragment = PropertyFragment;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* googleKey Not a pure module */
