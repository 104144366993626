// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ImageGallery = require("./ImageGallery.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var PropertyImageGallery_property_graphql = require("../../../__generated__/PropertyImageGallery_property_graphql.bs.js");

var getConnectionNodes = PropertyImageGallery_property_graphql.Utils.getConnectionNodes;

var convertFragment = PropertyImageGallery_property_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(PropertyImageGallery_property_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, PropertyImageGallery_property_graphql.node, convertFragment);
}

var PropertyFragment = {
  getConnectionNodes: getConnectionNodes,
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function PropertyImageGallery(props) {
  var property = RescriptRelay_Fragment.useFragment(PropertyImageGallery_property_graphql.node, convertFragment, props.property);
  var images = Curry._1(getConnectionNodes, property.images).map(function (img) {
        return img.fragmentRefs;
      });
  return JsxRuntime.jsx(ImageGallery.make, {
              images: images
            });
}

var make = PropertyImageGallery;

exports.PropertyFragment = PropertyFragment;
exports.make = make;
/* ImageGallery Not a pure module */
