// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");
var CalendarDate = require("../../../reason/common/types/CalendarDate.bs.js");
var AttributeListV5 = require("../../cap/Property/components/AttributeListV5.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var FragmentErrorBoundary = require("../ErrorHandling/FragmentErrorBoundary.bs.js");
var RescriptRelay_Fragment = require("rescript-relay/src/RescriptRelay_Fragment.bs.js");
var RentalPropertyLease_rentalProperty_graphql = require("../../../__generated__/RentalPropertyLease_rentalProperty_graphql.bs.js");

var convertFragment = RentalPropertyLease_rentalProperty_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(RentalPropertyLease_rentalProperty_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, RentalPropertyLease_rentalProperty_graphql.node, convertFragment);
}

var PropertyFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function RentalPropertyLease$DisplayAttributes(props) {
  return JsxRuntime.jsx(AttributeListV5.make, {
              data: [
                {
                  title: props.leaseLengthDisplayName + " lease"
                },
                {
                  title: "From " + CalendarDate.format(props.availableFrom)
                }
              ],
              size: props.size
            });
}

var DisplayAttributes = {
  make: RentalPropertyLease$DisplayAttributes
};

function RentalPropertyLease$WithoutErrorBoundary(props) {
  var match = RescriptRelay_Fragment.useFragment(RentalPropertyLease_rentalProperty_graphql.node, convertFragment, props.property);
  return JsxRuntime.jsx(RentalPropertyLease$DisplayAttributes, {
              leaseLengthDisplayName: match.leaseLength.displayName,
              availableFrom: match.availableFrom,
              size: props.size
            });
}

var WithoutErrorBoundary = {
  make: RentalPropertyLease$WithoutErrorBoundary
};

function RentalPropertyLease(props) {
  return JsxRuntime.jsx(FragmentErrorBoundary.make, {
              fragmentDisplayName: "lease details",
              children: JsxRuntime.jsx(RentalPropertyLease$WithoutErrorBoundary, {
                    property: props.property,
                    size: props.size
                  })
            });
}

var make = RentalPropertyLease;

exports.PropertyFragment = PropertyFragment;
exports.DisplayAttributes = DisplayAttributes;
exports.WithoutErrorBoundary = WithoutErrorBoundary;
exports.make = make;
/* CalendarDate Not a pure module */
